class ModuleState {
    
    constructor(){
        if(! ModuleState.instance){
            this.observableID = "module";
            this.observers = [];
            ModuleState.instance = this;
        }

        return ModuleState.instance;
    }
    
    addObserver(obj) {
        this.observers.push(obj);
    }
    
    removeObserver(obj) {
        this.observers.splice(this.observers.indexOf(obj), 1);
    }
    
    onStateChange() {
        for (var observer in this.observers) {
            this.observers[observer].onGlobalStateChange(this.observableID);
        }
    }
}

const instance = new ModuleState();

export default instance;


