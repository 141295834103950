import React from "react";
import {Button, ButtonToolbar} from 'react-bootstrap';
import EditEntity from './EditEntity.js'

class EntityView extends React.Component {
    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    onSelect() {
        console.log("selected");
    }

    onEdit(payload) {
        payload.path = this.props.entity._links.self.href;
        //console.log(payload);
        this.props.onUpdate(payload);
    }

    render() {
        //console.log(this.props);
        let fields = this.props.rfields.map( f => {
            let s = f.split('.');
            if (s.length === 1) {
                return <td key={this.props.entity[f]} >{this.props.entity[f]}</td>
            }
            else {
                return <td key={this.props.entity[f]} >{this.props.entity[s[0]][s[1]]}</td>
            }
        });

        return (
            <tr>
                { fields }
                <td>
                    <ButtonToolbar>
                        <Button variant="link" onClick={this.onSelect}>
                                Select
                        </Button>
                        <EditEntity
                            title={ this.props.title }
                            entity={ this.props.entity }
                            attributes={ this.props.attributes }
                            excludedFields={ this.props.excludedFields }
                            onUpdate={this.onEdit}/>
                    </ButtonToolbar>
                </td>

            </tr>
        )
    }
}

export default EntityView