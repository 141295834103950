import "bootstrap/dist/css/bootstrap.min.css";
import './css/main.css'
import React from 'react';
import AuthService from './services/AuthService.js';
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./Routes";
import Globals from './states/Globals.js';
import Navigation from "./components/Navigation.js"
import Breadcrumbs from "./components/Breadcrumbs.js"

if (!AuthService.initialized)
    await AuthService.initialize();

function App() {
  if (Globals.user === undefined) {
    return ( <div className="App">
                     <p>Loading...</p>
                 </div> );
  }
  return (
  <div className="App">
    <BrowserRouter>
        <Navigation />
        <Breadcrumbs />
        <NavRoutes />
    </BrowserRouter>
  </div>
  );
}

export default App;
