import React from "react";
import { Routes,
           Route, } from "react-router-dom";
import Home from "./components/Home";
//import Users from "./components/UserList";
import Clients from "./components/client/ClientList";
import ClientHome from "./components/client/ClientHome.js";
import AssignedModuleList from "./components/client/AssignedModuleList.js";
import ModuleCategoryList from './components/module/ModuleCategoryList.js';
import ModuleGroupList from './components/module/ModuleGroupList.js';
import ModuleList from './components/module/ModuleList.js';
import WebAnalytics from './components/module/webanalytics/WebAnalytics.js';
//import Stores from "./components/StoreList";
//import StoreDashboard from "./components/StoreDashboard";
//import Orders from "./components/reports/Orders.js";
//import Labor from "./components/reports/Labor.js";
//import StoreConfig from "./components/StoreConfig";

export default function NavRoutes() {
  /*
        <Route path="/users" exact component={Users} />
        <Route path="/stores" exact component={Stores} />
        <Route path="/storeDashboard" exact component={StoreDashboard} />
        <Route path="/store/config" exact component={StoreConfig} />
        <Route path="/reports/orders" exact component={Orders} />
        <Route path="/reports/labor" exact component={Labor} />
  */
  return (

      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/client" exact element={<ClientHome />} />
        <Route path="/clients" exact element={<Clients />} />
        <Route path="/assignedModules" exact element={<AssignedModuleList />} />
        <Route path="/modules" exact element={<ModuleList />} />
        <Route path="/moduleCategories" exact element={<ModuleCategoryList />} />
        <Route path="/moduleGroups" exact element={<ModuleGroupList />} />
        <Route path="/client/Google Analytics" exact element={<WebAnalytics />} />
      </Routes>

  );
}


