import Keycloak from 'keycloak-js';
import Globals from '../states/Globals.js';
import User from '../models/User';

class AuthService {

    constructor(){
        if(! AuthService.instance){
            this.initOptions = {
                url: process.env.REACT_APP_KEYCLOAK_URL,
                realm: process.env.REACT_APP_KEYCLOAK_REALM,
                clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
                onLoad: 'login-required', // check-sso | login-required
                redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
                KeycloakResponseType: 'code',
            }
            this.initialized = false;
            this.authService = new Keycloak(this.initOptions);
            AuthService.instance = this;
        }

        return AuthService.instance;
    }

    async initialize() {
        await this.authService.init({
          onLoad: this.initOptions.onLoad,
          KeycloakResponseType: 'code',
          silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then((auth) => {
          if (!auth) {
            window.location.reload();
          } else {
            Globals.setUser(new User(this.authService));
            this.authService.onTokenExpired = () => {
              console.log('token expired')
              this.authService.login();
            }
          }
        }, () => {
          console.error("Authenticated Failed");
        });
    }

    login() {
        console.log("Auth: logging in");
        this.authService.login();
    }

    logout() {
        console.log("Auth: logging out");
        this.authService.logout();
    }

}
const instance = new AuthService();
export default instance