import ClientState from './ClientState.js';
import StoreState from './StoreState.js';
import UserState from './UserState.js';
import ModuleState from './ModuleState.js';

class Globals {
    
    constructor(){
        if(! Globals.instance){
            this.client = null;
            this.store = null;
            this.user = null;
            this.module = null;
            this.redirect = "";
            Globals.instance = this;
        }

        return Globals.instance;
    }
    
    setRedirect(url) {
        this.redirect = url;
    }
    
    setClient(client) {
        this.client = client;
        ClientState.onStateChange();
    }
    
    setStore(store) { // can be a module
        this.store = store;
        StoreState.onStateChange();
    }
    
    setUser(user) {
        this.user = user;
        UserState.onStateChange();
    }

    setModule(module) {
        this.module = module;
        ModuleState.onStateChange();
    }
}

const instance = new Globals();

export default instance;


