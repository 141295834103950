import React from 'react';
import {
    Breadcrumb,
} from 'react-bootstrap';
import Globals from '../states/Globals.js';
import ClientState from '../states/ClientState.js';
import ModuleState from '../states/ModuleState.js';
import { withNavigation } from '../utils/navigation.js';
import 'material-symbols/index.css';

class Breadcrumbs extends React.Component {

    constructor(props){
        super(props);
        this.onGlobalStateChange = this.onGlobalStateChange.bind(this);
        ClientState.addObserver(this);
        ModuleState.addObserver(this);
    }

    onGlobalStateChange(global_state) {
        switch(global_state) {
            case "client":
                this.setState( {client: Globals.client.name});
                break;
            case "module":
                this.setState( {module: Globals.module.m.name});
                break;
        }
    }

    render() {
        const breadcrumbItems = [];
        breadcrumbItems.push(<Breadcrumb.Item key="home" href="/"><span className="material-symbols-outlined">home</span></Breadcrumb.Item>);
        if (Globals.client !== null)
            breadcrumbItems.push(<Breadcrumb.Item key="client" href="/client">{this.state.client}</Breadcrumb.Item>);
        if (Globals.module !== null) {
            let link = "/client/" + this.state.module;
            //console.log(link);
            breadcrumbItems.push(<Breadcrumb.Item key="module" href={ link }>{this.state.module}</Breadcrumb.Item>);
        }
        return (
            <Breadcrumb key="breadcrumb">
                {breadcrumbItems}
            </Breadcrumb>
        );
    }
}

export default withNavigation(Breadcrumbs)
