import React from "react";
import { useNavigate } from 'react-router-dom';
import {Button, ButtonToolbar} from 'react-bootstrap';
import Globals from '../../states/Globals.js';

function withNavigation(Component) {
    return props => <Component {...props} navigation={useNavigate()}/>;
}

class Client extends React.Component {
    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }
    
    onSelect() {
        Globals.setClient(this.props.client);
        this.props.navigation('/client');
    }
    
    render() {
        return (
            <tr>
                <td>{this.props.client.name}</td>
                <td>
                    <ButtonToolbar>
                        <Button variant="outline-primary" onClick={this.onSelect}>
                                Select
                        </Button>
                        <Button variant="outline-secondary" onClick={this.handleEdit}>
                                Edit
                        </Button>
                    </ButtonToolbar>
                </td>
                    
            </tr>
        )
    }
}

export default withNavigation(Client)

