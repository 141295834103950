import React from "react";
import {ButtonToolbar, ButtonGroup, Button, Container, Row, Col, Table} from 'react-bootstrap';
import Globals from "../../states/Globals.js"
import ModuleConfigEdit from './ModuleConfigEdit';
import AssignedModuleService from '../client/AssignedModuleService';

export default class ModuleHome extends React.Component {

    constructor(props){
        super(props);
        this.state = { module: Globals.module };
        //console.log(Globals.module);
        this.onConfigUpdate = this.onConfigUpdate.bind(this);
        this.entityUpdated = this.entityUpdated.bind(this);
    }

    onConfigUpdate(payload) {
        this.state.module.a.configuration = JSON.stringify(payload);
        this.state.module.a.path = this.state.module.a._links.self.href;
        AssignedModuleService.updateEntity(this.state.module.a, this.entityUpdated);
    }

    entityUpdated(isUpdated) {

        if(isUpdated) {
        console.log(this.state.module.a.configuration);
            let m = this.state.module;
            // needs spinner
            Globals.setModule(m);
            this.setState({module: m});
            this.render();
        }
    }

    render(child_content) {
        let configuration = this.state.module.a.configuration;
        if (configuration === null) {
           configuration = this.state.module.m.configuration;
        }

        return (
            <Container>
                <ButtonToolbar className="justify-content-between">
                    <p>{ this.state.module.m.name }</p>
                    <ModuleConfigEdit attributes={ configuration } onUpdate={ this.onConfigUpdate }/>
                </ButtonToolbar>
                {child_content}
            </Container>
        );
    }
}