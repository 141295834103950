class ClientState {
    
    constructor(){
        if(! ClientState.instance){
            this.observableID = "client";
            this.observers = [];
            ClientState.instance = this;
        }

        return ClientState.instance;
    }
    
    addObserver(obj) {
        this.observers.push(obj);
    }
    
    removeObserver(obj) {
        this.observers.splice(this.observers.indexOf(obj), 1);
    }
    
    onStateChange() {
        for (var observer in this.observers) {
            this.observers[observer].onGlobalStateChange(this.observableID);
        }
    }
}

const instance = new ClientState();

export default instance;


