import ApiClient from '../api/ApiClient';
import UserState from '../states/UserState.js';

class Service {
    
    constructor(reference){
        //console.log('service init: ', reference);

        this.reference = reference;
        this.loaded = false;
        this.entities = [];
        this.schema = {};
        this.attributes = [];
        this.excludedFields = [];
        this.listFields = ['name'];
        this.listColumns = ['Name'];
        this.pageSize = 60;
        this.links = [];
        this.page = {};
        this.isInit = false;

        this.init = this.init.bind(this);

        UserState.addObserver(this);
    }


    onGlobalStateChange(global_state) {
        switch(global_state) {
            case "user":
                if (!this.isInit) { this.init(); }
                break;
        }
    }

    async init() {
        //console.log("init");
        this.schema = await ApiClient.getFullEntitySchema(this.reference);
        this.attributes = this.schema.schema;
        this.isInit = true;
    }

    async get(entity, callback) {
        return await ApiClient.get(entity);
        //console.log(r);
    }

    async getList(page, size, callback, entity = this.reference) {
        let result = await ApiClient.getList(entity, page, this.pageSize, this.schema);
        this.links = result.entities._links;
        this.page = result.entities.page;
        //this.attributes = result.schema;
        callback(result.entities._embedded[this.reference]);
    }

    async getFullList(entity = this.reference) {
        let result = await ApiClient.getList(entity, 0, 1000);
        return result.entities._embedded[this.reference];
    }

    postEntity(entity, callback) {
        return ApiClient.post(this.reference, entity, callback);
    }

    updateEntity(entity, callback) {
        return ApiClient.update(entity.path, entity, callback);
    }
}

export default Service


