import React        from "react";
import { Link }     from "react-router-dom";
import {
    Navbar,
    Nav,
    NavDropdown,
}                   from 'react-bootstrap';
import Globals      from "../states/Globals.js"
import AuthService  from '../services/AuthService.js';
import 'material-symbols/index.css';

export default class Navigation extends React.Component {

  render () {
      let admin = "";
      if (Globals.user.hasRole("local7_admin")) {
          admin = <NavDropdown title="Admin" id="admin-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/clients" href="/clients">
                          Clients
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/assignedModules" href="/assignedModules">
                          Assigned Modules
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/modules" href="/modules">
                            Modules
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/moduleCategories" href="/moduleCategories">
                            Module Categories
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/moduleGroups" href="/moduleGroups">
                            Module Groups
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} href="/users">Users</NavDropdown.Item>
                  </NavDropdown>;
      }
          return (
               <Navbar collapseOnSelect bg="light" expand="lg">
                    <Navbar.Brand>
                      <Nav.Link as={Link} to="/home">
                      <img
                          src="../imgs/Local7_Logo.png"
                          width="100"
                          height="27"
                          className="d-inline-block align-top"
                          alt="Local7 logo"
                        />
                      </Nav.Link>
                    </Navbar.Brand>
                        <Nav className="mr-auto">
                          {admin}
                          <NavDropdown title="Help" id="help-nav-dropdown">
                          </NavDropdown>

                          <NavDropdown title={Globals.user.name} id="help-nav-dropdown">
                                <NavDropdown.Item onClick={() => { AuthService.logout(); }}>Log out</NavDropdown.Item>
                           </NavDropdown>


                        </Nav>
               </Navbar>
          )
      }
}