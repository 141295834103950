import React from "react";

import ListView from '../ListView.js';

class AssignedModuleList extends React.Component {

    render() {
        return (<ListView  title='Assigned Module' entity='assignedModules' />)
    }
}

export default AssignedModuleList