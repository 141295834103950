export default class User {

    constructor(session) {
        if (session !== undefined && session !== null) {
            this.local_session = session;
            this.local_token = session.token;
            this.name = session.tokenParsed.name;
            this.email = session.tokenParsed.email;
            this.roles = session.tokenParsed.realm_access.roles;
        }
        else {
            this.local_session = "";
            this.local_token = "";
            this.name = "";
            this.email = "";
            this.roles = [];
        }
    }

    hasRole(role) {
        for (var r of this.roles) {

            if (role === r) {
              return true;
            }
        }
        return false;
    }

}