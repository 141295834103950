import React from "react";
import {ButtonToolbar, Container, Row, Col, Table} from 'react-bootstrap';
import EntityView from './EntityView.js';
import CreateEntity from './CreateEntity.js';
import ServiceFactory from '../services/ServiceFactory.js';

class ListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                        title: props.title
                        , entity: props.entity
                        , entities: []
                        , page: 1
                        , pageSize: 10
                        , attributes: []
                        , links: {}
                        , loaded: false
                    };
        this.service = ServiceFactory.getService(this.state.entity);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.entitiesLoaded = this.entitiesLoaded.bind(this);
        this.entityCreated = this.entityCreated.bind(this);
        this.entityUpdated = this.entityUpdated.bind(this);
    }

    componentDidMount() {
        this.service.getList(this.state.page, this.state.pageSize, this.entitiesLoaded);
    }

    onCreate(entity) {
        this.service.postEntity(entity, this.entityCreated);
    }

    onUpdate(payload) {
        this.service.updateEntity(payload, this.entityUpdated);
    }

    entitiesLoaded(entities) {
        this.setState({
            entities: entities,
            attributes: this.service.attributes,
            pageSize: this.service.pageSize,
            links: this.service.links,
            loaded: true,});
    }

    entityCreated(isCreated) {
        if(isCreated) {
            // needs spinner
            this.setState({loaded: false});
            this.service.getList(this.state.page, this.state.pageSize, this.entitiesLoaded);
        }
    }

    entityUpdated(isUpdated) {
            if(isUpdated) {
                // needs spinner
                this.setState({loaded: false});
                this.service.getList(this.state.page, this.state.pageSize, this.entitiesLoaded);
            }
        }

    render() {
        if (!this.state.loaded) {
            return (<p>loading...</p>);
        }
        //console.log(this.state.entities);
        const entities = this.state.entities.map(db_entity =>
            <EntityView
                key={db_entity._links.self.href}
                entity={db_entity}
                rfields={this.service.listFields}
                title={ this.state.title }
                attributes={ this.state.attributes }
                excludedFields={ this.service.excludedFields }
                onSelect={ null }
                onUpdate={ this.onUpdate }
                />
        );
        //console.log(entities);
        const headers = this.service.listColumns.map( c => <th key={c}  > { c } </th> );

        return (
            <div>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col className="align-middle">
                            <h2 className="text-center">{ this.state.title }</h2>
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                            <ButtonToolbar className="mb-1">
                                <CreateEntity
                                    title={ this.state.title }
                                    attributes={ this.state.attributes }
                                    excludedFields={ this.service.excludedFields }
                                    onCreate={ this.onCreate } />
                            </ButtonToolbar>
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                            <Table striped bordered hover size="sm">
                                <tbody>
                                    <tr>
                                            { headers }
                                            <th></th>
                                    </tr>
                                    {entities}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default ListView