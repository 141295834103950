import Service from '../../services/Service';

class ModuleGroupService extends Service{

    constructor(){
        super("moduleGroups");
        if(!ModuleGroupService.instance){
            ModuleGroupService.instance = this;
        }

        return ModuleGroupService.instance;
    }

}

const instance = new ModuleGroupService();
export default instance