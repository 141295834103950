import Service from '../../services/Service';
import Globals from '../../states/Globals.js';

class ClientService extends Service{
    
    constructor(){
        super("clients");
        if(! ClientService.instance){
            ClientService.instance = this;
        }
        return ClientService.instance;
    }
    
    getCurrentClient(callback) {
        var link = Globals.user._links.client.href;
        this.getEntity(link, callback);
    }

    postClient(client) {
        return this.postEntity(client);
    }
}

const instance = new ClientService();
export default instance
