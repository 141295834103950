import React from "react";

import ListView from '../ListView.js';

class ModuleCategoryList extends React.Component {

    render() {
        return (<ListView  title='Module Categories' entity='moduleCategories' />)
    }
}

export default ModuleCategoryList