import React from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Form} from 'react-bootstrap';
import ServiceFactory from '../services/ServiceFactory.js';
//import { setRefToAttributes } from '../api/HalTools.tsx';


class EditEntity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                        title: props.title
                        , attributes: props.attributes
                        , show: false
                    };

        this.fieldRefs = [];
        props.attributes.forEach( a => {
            this.fieldRefs[a.name] = 'ce_' + a.name;
        });
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose() {
        this.setState({ show: false });
    }

    async handleShow() {
        let listData = [];
        for (var i in this.state.attributes) {
            if (this.state.attributes[i].type === 'SAFE'
                && !this.props.excludedFields.includes(this.state.attributes[i].name)) {
                listData[this.state.attributes[i].name] =
                    (await this.getEntityList(this.state.attributes[i].list)).map(item => {
                        if (this.props.entity[this.state.attributes[i].name].name === item.name) {
                            return <option key={item._links.self.href} value={item._links.self.href} selected>{item.name}</option>
                        }
                        return <option key={item._links.self.href} value={item._links.self.href} >{item.name}</option>
                    });
            }
        }
        this.setState(
            {
                show: true,
                listData: listData,
            }
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        let payload = {};
        this.state.attributes.forEach(r => {
            if (!this.props.excludedFields.includes(r.name)) {
                payload[r.name] = ReactDOM.findDOMNode(this.refs[this.fieldRefs[r.name]]).value.trim();
            }
        });
        this.props.onUpdate(payload);
        this.handleClose();
    }

    async getEntityList(list) {
        return await ServiceFactory.getService(list).getFullList();
    }

    render() {
        let fields = [];
        if (this.state.show) {
            //.log(this.props.entity);
            fields = this.state.attributes.map(attribute => {
                if (!this.props.excludedFields.includes(attribute.name)) {

                    switch(attribute.type) {
                        case 'SEMANTIC':
                           return <Form.Group controlId="form_{ attribute.name }">
                                       <Form.Label>{attribute.name}</Form.Label>
                                       <Form.Control ref={this.fieldRefs[attribute.name]} type="text" defaultValue={ this.props.entity[attribute.name] }/>
                                  </Form.Group>
                        case 'SAFE' :
                          return <Form.Group controlId="form_{ attribute.name }">
                                      <Form.Label>{attribute.name}</Form.Label>
                                      <Form.Control
                                          ref={this.fieldRefs[attribute.name]}
                                          as="select"
                                          placeholder={attribute.name} >
                                      <option> {attribute.name} </option>
                                        {this.state.listData[attribute.name]}
                                      </Form.Control>
                                 </Form.Group>
                        default:
                            return null;
                    }
                }
                return null;
            });
        }
        return (
            <div>
                <Button onClick={this.handleShow} variant="link" size="sm">Edit</Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Edit { this.state.title }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            { fields }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" size="sm" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" size="sm" onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default EditEntity