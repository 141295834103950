import Service from '../../services/Service';

class ModuleService extends Service{

    constructor(){
        super("modules");
        if(! ModuleService.instance){
            ModuleService.instance = this;
        }

        return ModuleService.instance;
    }

}

const instance = new ModuleService();
export default instance