import React from "react";

export default class Home extends React.Component {

render () {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Dashboard</h1>
        <p>Analytics made simple</p>
      </div>
    </div>
  );
  }
}


