import Globals from '../states/Globals.js';
import Auth from '../services/AuthService';

class ApiClient {

    constructor() {
        if(! ApiClient.instance){
            ApiClient.instance = this;
        }
        return ApiClient.instance;
    }

    header() {
        let header = {};
        header['Accept'] = 'application/hal+json';
        header['Content-type'] = 'application/json; charset=UTF-8';
        header['Authorization'] = 'Bearer ' + Globals.user.local_token;
        return header;
    }

    async getEntitySchema(entity) {
        return await fetch(process.env.REACT_APP_DATA_SERVICE_PROFILE_URL + entity, {
            method: 'GET',
            headers: this.header(),
        })
        .then(function(response) {
            let resp = response;
            ApiClient.instance.checkStatus(resp.status)
            if (response.ok) {
                return response.json()
           }
           throw new Error("API Exception");
        })
        .then((result) => {
            return result.alps.descriptor;
        })
        .catch((err) => {
            return { error: err.message}
        });
    }

    async getEntitySchemaJson(entity) {
            let h = this.header();
            h['Accept'] = 'application/schema+json';
            return await fetch(process.env.REACT_APP_DATA_SERVICE_PROFILE_URL + entity, {
                method: 'GET',
                headers: h,
            })
            .then(function(response) {
                let resp = response;
                ApiClient.instance.checkStatus(resp.status)
                if (response.ok) {
                    return response.json()
               }
               throw new Error("API Exception");
            })
            .then((result) => {
                return result;
            })
            .catch((err) => {
                return { error: err.message}
            });
        }

    async followEntitySchema(path) {
            return await fetch(path, {
                method: 'GET',
                headers: this.header(),
            })
            .then(function(response) {
                let resp = response;
                ApiClient.instance.checkStatus(resp.status)
                if (response.ok) {
                    return response.json()
               }
               throw new Error("API Exception");
            })
            .then((result) => {
                return result.alps.descriptor;
            })
            .catch((err) => {
                return { error: err.message}
            });
        }

    async resolveSchema(schema) {
        let attributes = [];
        let entity = await this.getEntitySchema(schema);
        if (entity.error === undefined) {
            for (var i in entity[0].descriptor) {
                if (entity[0].descriptor[i].type === 'SEMANTIC') {
                    attributes[i] = entity[0].descriptor[i];
                }
                else if (entity[0].descriptor[i].type === 'SAFE') {
                    attributes[i] = entity[0].descriptor[i];
                    let e = await this.followEntitySchema(entity[0].descriptor[i].rt);
                    attributes[i].list = e[1].name;
                }
            }
        }
        return attributes;
    }

    checkStatus(responseStatus) {
        switch(responseStatus) {
            case 200:
            case 201:
                return true;
            case 401:
                this.status401();
                return false;
            default:
                return false;
        }
    }

    status401() {
        console.log("not logged in, prompting login");
        Auth.login();
    }

    async get(entity = process.env.REACT_APP_DATA_SERVICE_URL) {
        let r = {};
        r =  await fetch(entity, {
        method: 'GET',
        headers: this.header(),
        })
        .then((response) => response.json())
        //.then((result) => {return result;} )
        .catch((err) => {
            console.log(err.message);
        });

        //console.log(r);
        return r;
    }

    post(entity, postBody, callback) {
            fetch(process.env.REACT_APP_DATA_SERVICE_URL + entity, {
            method: 'POST',
            headers: this.header(),
            body: JSON.stringify(postBody),
            })
            .then((response) => {
                if (response.status === 200) {
                response.json()
                }
                else if (response.status === 201) {
                    callback(true);
                }
                else if (response.status === 400) {
                    return { error: 'Status 400'}
                }
                else if (response.status === 401) {
                    this.status401();
                    return { error: 'Status 401'}
                }
            })
            .catch((err) => {
                console.log(err.message);
                return { error: err.message}
            });
        }

    update(entity, postBody, callback) {
        fetch(entity, {
        method: 'PUT',
        headers: this.header(),
        body: JSON.stringify(postBody),
        })
        .then((response) => {
            if (response.status === 200) {
                callback(true);
            }
            else if (response.status === 400) {
                return { error: 'Status 400'}
            }
            else if (response.status === 401) {
                this.status401();
                return { error: 'Status 401'}
            }
        })
        .catch((err) => {
            console.log(err.message);
            return { error: err.message}
        });
    }

    async getFullEntitySchema(entity) {
        let response = {};
        response.schema = await this.resolveSchema(entity);
        //console.log(response.schema);
        response.jsonSchema = await this.getEntitySchemaJson(entity);
        return response;
    }

    async getList(entity, page, size, schema = null) {
        let response = {};
        response.entities = await fetch(
            process.env.REACT_APP_DATA_SERVICE_URL + entity,
            {
                method: 'GET',
                headers: this.header(),
            }
        )

        .then(function(response) {
            let resp = response;
            ApiClient.instance.checkStatus(resp.status)
            if (response.ok) {
                return response.json()
           }
           throw new Error("API Exception");
        })
        .then((result) => {return result;} )
        .catch((err) => {
                console.log(err.message);
        });

        if (schema === null) {
            return response;
        }
        return await this.resolveList(response, entity, schema);
    }

    async resolveList(list, entity, schema) {
        let listItems = this.parseListItems(schema.schema);

        for (var i in list.entities._embedded[entity]) {
            for (var j in listItems) {
                //console.log(list.entities._embedded[entity][i]);
                list.entities._embedded[entity][i][listItems[j]] = await this.get(list.entities._embedded[entity][i]._links[listItems[j]].href);
                //console.log(list.entities._embedded[entity][i]);
            }
        }

        return list;
    }

    parseListItems(list) {
        let items = [];
        list.forEach( l => {
            if(l.type === 'SAFE') {
                items.push(l.name);
            }
        });
        return items;
    }
}

const instance = new ApiClient();
export default instance;