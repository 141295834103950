import React from "react";

import {ButtonToolbar, Container, Row, Col, Table} from 'react-bootstrap';

import ClientService from './ClientService.js';
import CreateClientDialog from "./CreateClientDialog";
import Client from './Client';

class ClientList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {page: 1, clients: [], pageSize: 10, attributes: [], links: {}, loading: false};
        this.onCreate = this.onCreate.bind(this);
        this.clientsLoaded = this.clientsLoaded.bind(this);
    }

    componentDidMount() {
        if (!this.state.loading) {
            this.loading = true;
            ClientService.getList(this.state.page, this.state.pageSize, this.clientsLoaded);
        }
    }

    clientsLoaded(clients) {
        this.setState({
            clients: clients,
            attributes: ClientService.attributes,
            pageSize: ClientService.pageSize,
            links: ClientService.links,
            loading: false,});
    }

    onCreate(newClient) {
        ClientService.postClient(newClient);
    }

    render() {
        const clients = this.state.clients.map(db_client =>
			<Client key={db_client._links.self.href} client={db_client}/>
		);
        return (
            <div> 
                <Container>
                    <Row className="justify-content-md-center">
                        <Col className="align-middle">
                            <h2 className="text-center">Clients</h2>
                        </Col>
                     </Row>
                     <Row>
                        <Col>     
                            <ButtonToolbar className="mb-1">
                                <CreateClientDialog attributes={this.state.attributes} onCreate={this.onCreate}/>
                            </ButtonToolbar>
                        </Col>
                     </Row>       
                     <Row>
                        <Col>
                            <Table striped bordered hover size="sm">
                                <tbody>
                                    <tr>
                                            <th>Name</th>
                                            <th></th>
                                    </tr>
                                    {clients}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }   
}

export default ClientList


