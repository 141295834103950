class StoreState {
    
    constructor(){
        if(! StoreState.instance){
            this.observableID = "store";
            this.observers = [];
            StoreState.instance = this;
        }

        return StoreState.instance;
    }
    
    addObserver(obj) {
        this.observers.push(obj);
    }
    
    removeObserver(obj) {
        this.observers.splice(this.observers.indexOf(obj), 1);
    }
    
    onStateChange() {
        for (var observer in this.observers) {
            this.observers[observer].onGlobalStateChange(this.observableID);
        }
    }
}

const instance = new StoreState();

export default instance;


