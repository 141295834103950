import React from "react";

import ListView from '../ListView.js';

class ModuleList extends React.Component {

    render() {
        return (<ListView  title='Modules' entity='modules' />)
    }
}

export default ModuleList