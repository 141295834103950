import React from "react";
import './module.css'
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Spinner} from 'react-bootstrap';
import Globals from '../../states/Globals.js';
import { withNavigation } from '../../utils/navigation';
import '/node_modules/react-vis/dist/style.css';
import {XYPlot, XAxis, YAxis, HorizontalRectSeries} from 'react-vis';

class ModuleWidget extends React.Component {

    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(e) {
        e.preventDefault();
        //console.log('widget selected');
        Globals.setModule(this.props.module);
        this.props.navigation(this.props.module.m.name);
    }

    render() {
        //console.log(this.props.module);
        const bd = [{x:1, y:1}];
        return (<Container ref={ this.props.module.m.name }  className="dash-widget">
            <Link onClick={ this.onSelect }><p>{ this.props.module.m.name }</p></Link>
            <div className="chart-pad">
                <XYPlot height={500} width={250} margin={{left: 50, top: 50}}>
                <XAxis tickLabelAngle={-75} orientation="top"/>
                <YAxis tickFormat= {tick => tick * -1}/>
                <HorizontalRectSeries data={bd} />
                </XYPlot>
            </div>
            </Container>);
    }
}

export default withNavigation(ModuleWidget)