import Service from '../../services/Service';

class AssignedModuleService extends Service{

    constructor(){
        super("assignedModules");

        this.excludedFields = ['assignedAt', 'assignedBy', 'unassignedAt', 'unassignedBy'];
        this.listFields = ['module.name', 'client.name'];
        this.listColumns = ['Module', 'Client'];
        if(!AssignedModuleService.instance){
            AssignedModuleService.instance = this;
        }

        return AssignedModuleService.instance;
    }

    async getListByClient(client) {
        let entity = this.reference + '/search/findByClient?client=' + client._links.client.href;
        //console.log(entity);
        let modLinks = await this.getFullList(entity);
        //console.log(modLinks);
        let modules = [];

        for (var i in modLinks) {
            let info = {}
            info.m = await this.get(modLinks[i]._links.module.href);
            info.a = modLinks[i];
            //console.log(m);
            modules.push(info);
        }
        return modules;
    }



}

const instance = new AssignedModuleService();
export default instance