import React from "react";
import ReactDOM from 'react-dom';
import {Button, Modal} from 'react-bootstrap';

class CreateClientDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {show: false};
        
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    
    handleSubmit(e) {
        e.preventDefault();
        const newClient = {};
        newClient.name = ReactDOM.findDOMNode(this.refs['clientNameInput']).value.trim();
        newClient.stores = [];
        console.log(newClient);
        this.props.onCreate(newClient);

        this.props.attributes.forEach(attribute => {
                ReactDOM.findDOMNode(this.refs[attribute]).value = '';
        });

        this.handleClose();
    }
    
    render() { 

        return (
           
            <div>
                <Button onClick={this.handleShow} variant="outline-primary">New</Button>
                
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Create new Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <p key="clientNameInput">
                                <input type="text" placeholder="Client Name" ref="clientNameInput" className="field"/>
                            </p>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    
}

export default CreateClientDialog


