class UserState {
    
    constructor(){
        if(! UserState.instance){
            this.observableID = "user";
            this.observers = [];
            UserState.instance = this;
        }

        //console.log('UserState');
        return UserState.instance;
    }
    
    addObserver(obj) {
        //console.log('new observer', obj);
        this.observers.push(obj);
    }
    
    removeObserver(obj) {
        this.observers.splice(this.observers.indexOf(obj), 1);
    }
    
    onStateChange() {
        //console.log('new user state');
        for (var observer in this.observers) {
            //console.log('new state: ', this.observers[observer]);
            this.observers[observer].onGlobalStateChange(this.observableID);
        }
    }
}

const instance = new UserState();

export default instance;