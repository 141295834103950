import React from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Form} from 'react-bootstrap';
import ServiceFactory from '../../services/ServiceFactory.js';
import 'material-symbols/index.css';

export default class ModuleConfigEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title
            , attributes: JSON.parse(props.attributes)
            , show: false
        };

        this.fieldRefs = Object.keys(this.state.attributes);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleShow() {
        this.setState(
            {
                show: true,
            }
        );
    }

        handleClose() {
            this.setState({ show: false });
        }

    handleSubmit(e) {
        e.preventDefault();
        let payload = {};
        this.fieldRefs.forEach(r => {
                payload[r] = ReactDOM.findDOMNode(this.refs[r]).value.trim();
        });
        this.setState({attributes: payload});
        this.props.onUpdate(payload);
        this.handleClose();
    }

    async getEntityList(list) {
        return await ServiceFactory.getService(list).getFullList();
    }

    render() {
        let fields = [];
        if (this.state.show) {
            //.log(this.props.entity);
            fields = this.fieldRefs.map(attribute => {
               return <Form.Group controlId="form_{ attribute }">
                           <Form.Label>{attribute}</Form.Label>
                           <Form.Control ref={attribute} type="text" defaultValue={ this.state.attributes[attribute] }/>
                      </Form.Group>
            });
        }
        return (
            <div>
                <Button onClick={this.handleShow} variant="outline-secondary" size="sm"><span className="material-symbols-outlined">&#xe8b8;</span></Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Edit { this.state.title }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            { fields }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" size="sm" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" size="sm" onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}