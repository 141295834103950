import React from "react";

import ListView from '../ListView.js';

class ModuleGroupList extends React.Component {

    render() {
        return (<ListView  title='Module Group' entity='moduleGroups' />)
    }
}

export default ModuleGroupList