import React from "react";
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import Globals from '../../states/Globals.js';
import ServiceFactory from '../../services/ServiceFactory.js';
import ModuleWidget from '../module/ModuleWidget.js';

export default class ClientHome extends React.Component{

    constructor(props) {
        super(props);
        this.state = {loaded: false};
        this.loaded = this.loaded.bind(this);
        this.assignedModulesService = ServiceFactory.getService('assignedModules');
    }

    async componentDidMount() {
        if (Globals.client !== null) {
            this.loaded(await this.assignedModulesService.getListByClient(Globals.client));
        }
    }

    loaded(modules) {
        this.setState({
            modules: modules,
            loaded: true,});
    }

    render() {
        if (!this.state.loaded) {
            return (<div><p>Loading...</p></div>)
        }

        const ResponsiveGridLayout = WidthProvider(Responsive);
        let k = 0;
        let x = 0;
        let y = 0;
        const moduleWidgets = this.state.modules.map( m => {
                let dg = { x: x, y: y, w: 1, h: 1, isResizable: false, static: true };
                if (x < 3) {
                    x++;
                }
                else {
                    x = 0;
                    y++;
                }
                k++;
                return <div key={k} data-grid={dg}><ModuleWidget key={k} module={m} /></div>
            }
        );
        //console.log(moduleWidgets);
        return (
            <ResponsiveGridLayout
                            className="layout"
                            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                            cols={{lg: 4, md: 3, sm: 2, xs: 1, xxs: 1}}>
                { moduleWidgets }
            </ResponsiveGridLayout>
        )
    }
}