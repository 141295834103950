import Service from '../../services/Service';

class ModuleCategoryService extends Service{

    constructor(){
        super("moduleCategories");
        if(!ModuleCategoryService.instance){
            ModuleCategoryService.instance = this;
        }

        return ModuleCategoryService.instance;
    }

}

const instance = new ModuleCategoryService();
export default instance