import ModuleCategoryService from '../components/module/ModuleCategoryService';
import ModuleGroupService from '../components/module/ModuleGroupService';
import ModuleService from '../components/module/ModuleService';
import AssignedModuleService from '../components/client/AssignedModuleService';
import ClientService from '../components/client/ClientService.js';

class ServiceFactory {

    getService(entity) {

        switch (entity) {
            case 'moduleCategories' :
                return ModuleCategoryService;
            case 'moduleGroups' :
                return ModuleGroupService;
            case 'modules' :
                return ModuleService;
            case 'assignedModules' :
                return AssignedModuleService;
            case 'clients' :
                return ClientService;
        }
    }
}
const instance = new ServiceFactory();
export default instance;